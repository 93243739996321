import React, { useCallback, useEffect, useState } from "react";
import { usePageConfig } from "../../context/PageConfigContext";
import { useEvent } from "../../context/EventContext";
import { useNavigate, useLocation } from "react-router-dom";

import NewLogo from "../templates/NewLogo/NewLogo";
import Loader from "../templates/Loader/Loader";

import styles from "./LandingDStyles.module.css";

const LandingD = () => {
  const { findDataComponent } = usePageConfig();
  const { analyticsEvent } = useEvent();
  const navigate = useNavigate();
  const location = useLocation();

  const [dataComponent, setDataComponent] = useState(null);
  const [progress, setProgress] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  const fireAnalytics = useCallback(() => {
    analyticsEvent("landingPageView");
  }, [analyticsEvent]);

  useEffect(() => {
    fireAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchDataComponent = async () => {
      const data = await findDataComponent("LandingD");
      setDataComponent(data);
    };
    fetchDataComponent();
  }, [findDataComponent]);

  useEffect(() => {
    if (dataComponent && dataComponent.loadingDelay && imageLoaded) {
      const totalDuration = dataComponent.loadingDelay * 1000;
      const interval = 50;
      let currentProgress = 0;

      const timer = setInterval(() => {
        currentProgress += (interval / totalDuration) * 100;
        if (currentProgress >= 100) {
          clearInterval(timer);
          currentProgress = 100;
          analyticsEvent("landingPageComplete");
          analyticsEvent("getStartedClicked", { page: "LandingD" });
          const searchParams = new URLSearchParams(location.search).toString();
          const quizPath = searchParams
            ? `/new/quiz/1?${searchParams}`
            : "/new/quiz/1";

          navigate(quizPath);
        }
        setProgress(currentProgress);
      }, interval);

      return () => clearInterval(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataComponent, imageLoaded]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  if (!dataComponent) {
    return <Loader />;
  }

  if (!imageLoaded) {
    return (
      <div className={styles.container}>
        <Loader />
        <img
          src={
            dataComponent.imgPath !== ""
              ? dataComponent.imgPath
              : "./img/landing_Mo.png"
          }
          alt="landingMO"
          style={{ display: "none" }}
          onLoad={handleImageLoad}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <NewLogo />
      <img
        src={
          dataComponent.imgPath !== ""
            ? dataComponent.imgPath
            : "./img/landing_Mo.png"
        }
        alt="landingMO"
        className={styles.landingImgMo}
      />
      <span className={styles.topText}>{dataComponent.topText}</span>
      <span className={styles.mainText}>{dataComponent.mainText}</span>
      <div className={styles.loaderContainer}>
        <div className={styles.textContainer}>
          <span className={styles.loadingText}>
            {dataComponent.loadingText}
          </span>
          <span className={styles.loadingText}>{Math.round(progress)}%</span>
        </div>
        <div className={styles.progressContainer}>
          <div className={styles.progress} style={{ width: `${progress}%` }} />
        </div>
      </div>
    </div>
  );
};

export default LandingD;
