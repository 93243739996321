import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { useRouteCorrection } from "./context/RouteHandlerContext";
import { useEnvContext } from "./context/EnvironmentContext";

import LandingD from "./pages/Landing_D/LandingD";
import NewQuiz from "./pages/NewQuiz";
import ABspecialOfferPlan from "./pages/Plan/ABspecialOfferPlan/ABspecialOfferPlan";
import RedesignThankYou from "./pages/ThankYou/RedesignThankYou/RedesignThankYou";
import DevMode from "./pages/dev_tools/ DevMode/ DevMode";

function RedirectWithParams() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search).toString();
  const newPath = searchParams ? `/new?${searchParams}` : "/new";

  // debug log
  useEffect(() => {
    console.log("Redirecting to:", newPath);
  }, [newPath]);

  return <Navigate to={newPath} replace />;
}

function RouteHandler() {
  const navigate = useNavigate();
  const location = useLocation();
  const { correctRoute } = useRouteCorrection();

  useEffect(() => {
    const path = location.pathname;
    const correctedPath = correctRoute(path);

    if (correctedPath !== null && correctedPath !== path) {
      const searchParams = new URLSearchParams(location.search);
      const queryString = searchParams.toString();
      const newPath = queryString
        ? `${correctedPath}?${queryString}`
        : correctedPath;

      navigate(newPath, { replace: true });
    }
  }, [location, navigate, correctRoute]);

  return null;
}

function App() {
  const { isProduction } = useEnvContext();

  return (
    <>
      <BrowserRouter
        history={createBrowserHistory({
          scrollRestoration: "auto",
        })}
      >
        <RouteHandler />
        {!isProduction && <DevMode />}

        <Routes>
          <Route path="/" element={<LandingD />} />

          {/* <Route path="/b" element={<LandingB />} /> */}
          {/* <Route path="/c" element={<LandingC />} /> */}

          {/* <Route path="/quiz/:id" element={<Quiz />} />
          <Route path="/quiz/:quizId/:id" element={<Quiz />} /> */}

          {/* <Route path="/plan" element={<ABtestPlanPage />} /> */}

          <Route path="/apply-discount" element={<ABspecialOfferPlan />} />

          {/* <Route path="/thankyou" element={<RedesignThankYou />} /> */}

          {/* <Route path="/reserve" element={<BlackFriday />} /> */}

          {/* <Route path="/lt-access" element={<BlackFriday />} /> */}

          <Route path="/new" element={<LandingD />} />

          <Route path="/new/quiz/:id" element={<NewQuiz />} />

          <Route path="/new/quiz/:quizId/:id" element={<NewQuiz />} />

          <Route path="/new/plan" element={<ABspecialOfferPlan />} />

          <Route path="/new/thankyou" element={<RedesignThankYou />} />

          <Route path="/dev/test/test_component" element={<>test page</>} />

          <Route
            path="/.well-known/apple-developer-merchantid-domain-association.txt"
            component={() => {
              window.location.href =
                "/apple-developer-merchantid-domain-association.txt";
              return null;
            }}
          />

          <Route path="*" element={<RedirectWithParams />} />
        </Routes>
      </BrowserRouter>
      <div className="hidden">{`Build version: ${process.env.REACT_APP_VERSION}`}</div>
    </>
  );
}

export default App;
