import React from "react";
import { usePageConfig } from "../../../../context/PageConfigContext";

import VideoPlayer from "./VideoPlayer/VideoPlayer";

import styles from "./WhatIsMemoryOS.module.css";

const WhatIsMemoryOS = () => {
  const { findDataComponent } = usePageConfig();
  const data = findDataComponent("WhatIsMemoryOS");

  return (
    <div className={styles.container}>
      <span className={styles.headText}>{data.headerText}</span>
      <VideoPlayer linkVideo={data.linkVideo} linkPoster={data.linkPoster} />
      <div className={styles.backgroundImg} />
    </div>
  );
};

export default WhatIsMemoryOS;
