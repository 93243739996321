import React, { createContext, useContext } from "react";

const RouteHandlerContext = createContext();

export const useRouteHandler = () => useContext(RouteHandlerContext);

export const RouteHandlerProvider = ({ children }) => {
  return (
    <RouteHandlerContext.Provider value={{}}>
      {children}
    </RouteHandlerContext.Provider>
  );
};

export const useRouteCorrection = () => {
  const correctRoutes = [
    "/new",
    "/new/plan",
    "/new/quiz",
    "/new/thankyou",
    "/b",
    "/c",
    "/quiz",
    "/plan",
    "/apply-discount",
    "/thankyou",
    "/reserve",
    "/lt-access",
    "/",
    "/dev/test/test_component",
  ];

  const correctRoute = (path) => {
    if (path.includes("?")) {
      return null;
    }

    const sortedRoutes = [...correctRoutes].sort((a, b) => b.length - a.length);

    for (const route of sortedRoutes) {
      if (path.startsWith(route)) {
        if (path === route) {
          return null;
        }

        const nextSegment = path.slice(route.length).split("/")[1];
        if (nextSegment) {
          return null;
        }

        return route;
      }
    }

    return "/new";
  };

  return { correctRoute };
};
