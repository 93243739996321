import React, { createContext, useContext, useState, useEffect } from "react";
import { initializeApp } from "firebase/app";

import { useEnvContext } from "./EnvironmentContext";
import { useDevMode } from "./DevMode";
import { useUrlContext } from "./urlContext";

import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import {
  getRemoteConfig,
  fetchAndActivate,
  getAll,
} from "firebase/remote-config";
import Loader from "../pages/templates/Loader/Loader";

const RemoteConfigContext = createContext();
export const useRemoteConfig = () => useContext(RemoteConfigContext);

export const FirebaseConfigProvider = ({ children }) => {
  const { isProduction } = useEnvContext();
  const { applyDiscountPage } = useUrlContext();
  const { devModeVariant } = useDevMode();

  const [appData, setAppData] = useState({});
  const [quizData, setQuiz] = useState({});
  const [feedbackData, setFeedbackData] = useState({});

  const [componentsData, setComponentsData] = useState({});
  const [newPageReviews, setNewPageReviews] = useState([]);
  const [newPageVideoReviews, setVideoReviews] = useState([]);
  const [fbEvent, setFbEvent] = useState(false);
  const [paymentButtons, setPaymentButtons] = useState({});
  const [optionTextData, setOptionTextData] = useState({});
  const [apiGeoUrl, setApiGeoUrl] = useState("");

  const [redirectUrl, setRedirectUrl] = useState("");
  const [emailProviders, setEmailProviders] = useState({});

  const [abTestCampaignName, setABtestCampaignName] = useState(null);
  const [abTestStartCampaignDate, setABtestStartCampaignDate] = useState(null);

  const [emailsToDisableAnalyticsEvent, setEmailsToDisableAnalyticsEvent] =
    useState([]);

  const [isConfigReady, setIsConfigReady] = useState(false);

  const firebaseConfig = {
    apiKey: "AIzaSyDDEVWxCxP8u_hvobFFusUHuOgkDuGCk6s",
    authDomain: "memoryos-web-funnel.firebaseapp.com",
    projectId: "memoryos-web-funnel",
    storageBucket: "memoryos-web-funnel.appspot.com",
    messagingSenderId: "1056744550522",
    appId: "1:1056744550522:web:f51c7114e8af84f7760bc3",
    measurementId: "G-QWT83PZ7FZ",
  };

  const app = initializeApp(firebaseConfig);
  const remoteConfig = getRemoteConfig(app);

  firebase.initializeApp(firebaseConfig);
  const analyticsFirebase = firebase.analytics();

  remoteConfig.settings.minimumFetchIntervalMillis = 100;

  const [remotePageContent, setPageContent] = useState({});

  const [abTestVariant, setAbTestVariant] = useState();
  const [currentABvariant, setCurrentAbVariant] = useState(null);

  useEffect(() => {
    const MAX_CHECKS = 15;
    let checkCount = 0;

    const checkForStoredVariant = () => {
      const storedVariant = localStorage.getItem("MemoryOSVWO");
      if (storedVariant) {
        return JSON.parse(storedVariant);
      }
      return null;
    };

    const checkAndSetVariant = () => {
      const variant = checkForStoredVariant();
      if (variant !== null) {
        setAbTestVariant(variant);
        return true;
      }
      return false;
    };

    if (!checkAndSetVariant()) {
      const intervalId = setInterval(() => {
        checkCount++;
        if (checkAndSetVariant() || checkCount >= MAX_CHECKS) {
          clearInterval(intervalId);
          if (checkCount >= MAX_CHECKS) {
            console.log(
              "Max checks reached. Setting abTestVariant to default."
            );
            setAbTestVariant(null);
          }
        }
      }, 100);

      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    if (abTestVariant || abTestVariant === null) {
      setData(remoteConfig, abTestVariant, devModeVariant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteConfig, devModeVariant, abTestVariant]);

  const setData = (remoteConfig, abTestVariant, devModeVariant) => {
    setCurrentAbVariant(abTestVariant);
    if (!abTestVariant) {
      console.error("Default variant: enabled");
    } else {
      console.info("Default variant: disabled");
    }

    fetchAndActivate(remoteConfig)
      .then(() => {
        const data = getAll(remoteConfig);
        if (applyDiscountPage) {
          const pageConfig = JSON.parse(
            data.staging_apply_discount_page._value
          );
          setPageContent(pageConfig);
        } else if (!applyDiscountPage) {
          if (!abTestVariant) {
            const pageConfig = JSON.parse(
              data.staging_default_plan_page._value
            );
            setPageContent(pageConfig);
            console.error(`Used default config for plan page`);
          } else if (typeof abTestVariant === "number") {
            const key = `staging_variant_${
              devModeVariant !== null ? devModeVariant : abTestVariant
            }`;
            const pageConfig = JSON.parse(data[key]._value);
            setPageContent(pageConfig);
          }
        }

        const dataConfig = JSON.parse(data.AppConfig._value);

        setEmailsToDisableAnalyticsEvent(
          dataConfig.teamEmailsToDisableAnalyticsEvents
        );

        setApiGeoUrl(dataConfig.appConfig.REACT_APP_GEO_API);

        setABtestCampaignName(
          dataConfig.appConfig.REACT_APP_A_B_TEST_CAMPAIGN_NAME
        );
        setABtestStartCampaignDate(
          dataConfig.appConfig.REACT_APP_START_TEST_CAMPAIGN_DATE
        );

        setAppData(dataConfig.appConfig);
        setFbEvent(dataConfig.fb_purchase_event);

        setPaymentButtons(dataConfig.payment_buttons);
        setRedirectUrl(dataConfig.reservePageRedirectURL);
        setEmailProviders(dataConfig.emailServices);

        const pageDataConfig = JSON.parse(data.New_Plan_Page._value);
        setComponentsData(pageDataConfig.newPlanPage);
        setNewPageReviews(pageDataConfig.reviews);
        setVideoReviews(pageDataConfig.videoReviews);

        const feedbackConfig = JSON.parse(data.Feedback._value);
        setFeedbackData(feedbackConfig);

        const firebaseTextOptionData = JSON.parse(data.OptionTextConfig._value);
        const stagingFirebaseTextOptionData = JSON.parse(
          data.StagingOptionTextConfig._value
        );

        if (isProduction) {
          const quizName = dataConfig.useQuiz;
          const qiuzDataFirebase = JSON.parse(data[quizName]._value);
          setQuiz(qiuzDataFirebase);
          setOptionTextData(firebaseTextOptionData);
        } else {
          const qiuzDataFirebase = JSON.parse(data.Quiz_Staging._value);
          setQuiz(qiuzDataFirebase);
          setOptionTextData(stagingFirebaseTextOptionData);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsConfigReady(true);
      });
  };

  if (isConfigReady === false) {
    return <Loader />;
  }

  const contextValue = {
    apiGeoUrl,
    abTestCampaignName,
    abTestStartCampaignDate,
    analyticsFirebase,
    paymentButtons,
    quizData,
    appData,
    feedbackData,
    componentsData,
    newPageReviews,
    newPageVideoReviews,
    fbEvent,
    redirectUrl,
    emailProviders,
    emailsToDisableAnalyticsEvent,
    currentABvariant,
    remotePageContent,
    optionTextData,
  };

  return (
    <RemoteConfigContext.Provider value={contextValue}>
      {children}
    </RemoteConfigContext.Provider>
  );
};
