import React, { createContext, useContext, useState, useEffect } from "react";
import Loader from "../pages/templates/Loader/Loader";

const UrlContext = createContext();

export const useUrlContext = () => {
  return useContext(UrlContext);
};

export const UrlProvider = ({ children }) => {
  const [queryParams, setQueryParams] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);

  const getCurrentPath = () => {
    return window.location.href;
  };

  useEffect(() => {
    const initializeContext = async () => {
      const storedParams =
        JSON.parse(localStorage.getItem("MemoryOSQueryParams")) || {};
      const params = getQueryParameters();
      let updatedParams = { ...params };

      if (Object.keys(params).length > 0) {
        setQueryParams(updatedParams);
        localStorage.setItem(
          "MemoryOSQueryParams",
          JSON.stringify(updatedParams)
        );
      } else if (Object.keys(storedParams).length > 0) {
        setQueryParams(storedParams);
      }

      setIsInitialized(true);
    };

    initializeContext();
  }, []);

  const getQueryParameters = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const dataUrl = {};

    for (const [key, value] of urlParams.entries()) {
      dataUrl[key] = value;
    }

    return dataUrl;
  };

  const [applyDiscountPage, setApplyDiscountPage] = useState(null);
  const path = window.location.href;

  useEffect(() => {
    if (path) {
      const hasDiscountPath = /\/apply-discount(\/|$|\?|#)/.test(path);

      if (hasDiscountPath) {
        localStorage.setItem("MemoryOSDownSale", false);
      }
      setApplyDiscountPage(hasDiscountPath);
    }
  }, [path]);

  const contextValue = {
    queryParams,
    getCurrentPath,
    applyDiscountPage,
  };

  if (!isInitialized) {
    return <Loader />;
  }

  return (
    <UrlContext.Provider value={contextValue}>{children}</UrlContext.Provider>
  );
};
