import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState,
} from "react";
import { usePaymentBlock } from "./PaymentBlockContext";
import { useRemoteConfig } from "./RemoteConfigContext";
import { useErrorHandler } from "./ErrorHandlerContext";
import { useUrlContext } from "./urlContext";
import { useEvent } from "./EventContext";
import { postData } from "../utils/fetch";
import { getCookie } from "../utils/cookie";
import loadScript from "../pages/Plan/loadScript";

const PaymentFormContext = createContext();

export const usePaymentForm = () => useContext(PaymentFormContext);

const initialState = {
  currentOrder: {},
  merchantDataSet: {},
  orderId: "",
  navigateObserver: false,
  scriptUrl: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CURRENT_ORDER":
      return { ...state, currentOrder: action.payload };
    case "SET_MERCHANT_DATA":
      return { ...state, merchantDataSet: action.payload };
    case "SET_ORDER_ID":
      return { ...state, orderId: action.payload };
    case "SET_NAVIGATE_OBSERVER":
      return { ...state, navigateObserver: action.payload };
    case "SET_SCRIPT_URL":
      return { ...state, scriptUrl: action.payload };
    default:
      return state;
  }
};

export const PaymentFormProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { appData } = useRemoteConfig();
  const { setStartPaymentForm, loadingForm } = usePaymentBlock();
  const { analyticsEvent } = useEvent();
  const { queryParams, getCurrentPath } = useUrlContext();
  const { setErrorHandler } = useErrorHandler();

  const removeUndefinedOrNullFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(
        ([_, value]) => value !== undefined && value !== null
      )
    );
  };

  const sentPurchaseEvent = useCallback(() => {
    const localStorageData = localStorage.getItem("MemoryOSOrderLocalData");
    const currentOrder = state.currentOrder;

    if (localStorageData || currentOrder) {
      try {
        const parsedData = JSON.parse(localStorageData);
        const eventId = parsedData.capiPurchEventID;

        if (currentOrder) {
          localStorage.removeItem("MemoryOSQuizData");
          localStorage.removeItem("MemoryOSQuizLastUpdate");
          localStorage.removeItem("MemoryOSUpgradeDiscount");
          const sendParams = {
            event_category: "purchase",
            value: currentOrder.full_price,
            currency: "USD",
            description: currentOrder.plan_name,
            event_id: eventId,
            product_id: currentOrder.product_id,
          };
          analyticsEvent("purchase", sendParams);
          analyticsEvent("conversion");
          analyticsEvent("pageView");
        }
      } catch (error) {
        console.error("Error parsing localStorage data:", error);
      }
    }
  }, [analyticsEvent, state]);

  const getOrderSummaryData = async () => {
    const savedData = JSON.parse(localStorage.getItem("MemoryOSUserData"));

    const email = savedData.userEmail;
    const name = savedData.userName;

    const checkSendData = {
      origin: appData.REACT_APP_ORIGIN,
      product_id: state.currentOrder?.product_id,
      is_paypal_order: true,
      customer_email: email,
      customer_name: name,
      fbc: getCookie("_fbc"),
      fbp: getCookie("_fbp"),
      snap_uuid_c1: getCookie("_scid"),
      snap_click_id: queryParams.ScCid || queryParams.sccid || queryParams.scid,
      ttp: getCookie("_ttp"),
      ttclid: queryParams.ttclid,
      campaign: queryParams.utm_campaign ?? queryParams.campaign,
      placement:
        queryParams.utm_medium ?? queryParams.medium ?? queryParams.placement,
      source: queryParams.utm_source ?? queryParams.source,
      content: queryParams.utm_content ?? queryParams.content,
      page_url: await getCurrentPath(),
    };
    console.log(`Current productID: ${checkSendData.product_id}`);
    console.warn("payment form sent data:", checkSendData);

    return removeUndefinedOrNullFields(checkSendData);
  };

  const prepareOrder = async () => {
    const getMemoryOSOrderLocalData = JSON.parse(
      localStorage.getItem("MemoryOSOrderLocalData")
    );

    const data = await getOrderSummaryData();

    try {
      const startTime = performance.now();
      const response = await postData(
        `${appData.REACT_APP_API_ENDPOINT}/order/prepare`,
        data,
        true
      );

      getMemoryOSOrderLocalData.capiPurchEventID =
        response.capi_purchase_event_id;
      localStorage.setItem(
        "MemoryOSOrderLocalData",
        JSON.stringify(getMemoryOSOrderLocalData)
      );

      const endTime = performance.now();
      const timeout = Math.max(0, 2000 - (endTime - startTime));
      await new Promise((resolve) => setTimeout(resolve, timeout));

      const { merchant_id, signature, paymentIntent } = response.merchant_data;
      const merchantData = {
        merchant: merchant_id,
        signature,
        paymentIntent,
      };

      if (Object.keys(merchantData).length > 0) {
        dispatch({ type: "SET_MERCHANT_DATA", payload: merchantData });
      } else {
        console.log("Merchant data is empty!");
        setErrorHandler("Merchant data is empty!");
      }

      dispatch({ type: "SET_ORDER_ID", payload: response.order_id });

      if (response.paypal_data) {
        const scriptUrl = response.paypal_data.script_url;
        loadScript(scriptUrl);
        dispatch({ type: "SET_SCRIPT_URL", payload: scriptUrl });
      } else {
        dispatch({ type: "SET_SCRIPT_URL", payload: "" });
      }

      const orderData = state.currentOrder;

      const eventParams = {
        value: orderData.full_price,
        product_id: orderData.product_id,
        capi_id: response.capi_purchase_event_id,
      };

      analyticsEvent("beginCheckout", eventParams);

      localStorage.setItem(
        "MemoryOSPlanData",
        JSON.stringify(
          `${orderData.thankyou_page_title} ${orderData.plan_name}${
            state.currentOrder.trial_status ? ", 7 day trial" : ""
          }`
        )
      );
    } catch (error) {
      if (error.message.includes("400")) {
        setStartPaymentForm(false);
        return loadingForm("StartActiveSubscForm");
      }
      console.error("Error parsing response or setting merchant data:", error);
      setErrorHandler("An error occurred during the transaction");
    }

    return false;
  };

  const [isLoading, setIsLoading] = useState(false);

  const [purchaseEventSent, setPurchaseEventSent] = useState(false);

  const checkOrderStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await postData(
        `${appData.REACT_APP_API_ENDPOINT}/order/data`,
        {
          order_id: state.orderId,
        },
        true
      );

      if (response.order_state === "FULFILLED" && !purchaseEventSent) {
        localStorage.setItem("MemoryOSOrderData", JSON.stringify(response));
        sentPurchaseEvent();
        setPurchaseEventSent(true);
        dispatch({ type: "SET_NAVIGATE_OBSERVER", payload: true });
        setIsLoading(false);
        return true;
      }
      setIsLoading(false);
      return false;
    } catch (error) {
      console.error("An error occurred during the transaction:", error);
      setIsLoading(false);
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.orderId, purchaseEventSent, sentPurchaseEvent, dispatch]);

  const startOrderStatusCheck = useCallback(() => {
    setIsLoading(true);
    const intervalId = setInterval(async () => {
      const fulfilled = await checkOrderStatus();
      if (fulfilled) {
        clearInterval(intervalId);
        setIsLoading(false);
      }
    }, 2000);
    return () => {
      clearInterval(intervalId);
      setIsLoading(false);
    };
  }, [checkOrderStatus]);

  const contextValue = {
    ...state,
    prepareOrder,
    startOrderStatusCheck,
    setCurrentOrder: (order) =>
      dispatch({ type: "SET_CURRENT_ORDER", payload: order }),
    isLoading,
  };

  return (
    <PaymentFormContext.Provider value={contextValue}>
      {children}
    </PaymentFormContext.Provider>
  );
};
